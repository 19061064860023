@import 'variables.scss';

.trigger {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    &.inactive {
        pointer-events: none;
    }
}

.cardContent {
    height: 100%;
    display: flex;
    flex-direction: column;

    .meta {
        color: black;
        padding: 4px;
        font-size: 11px;
        opacity: 0.7;
        position: absolute;
    }

    .image {
        flex: 1 1 auto;

        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 60%;
            max-height: 100%;
        }
    }

    .panel {
        flex: 0 0 auto;

        display: flex;
        flex-direction: column;
        justify-content: center;

        .progress, .fatProgress {
            background-color: $themeColor;
            height: 6px;
            width: 0;

            transition: width .2s ease;
        }
        
        .fatProgress {
            background-color: #c8ae00;
        }
    }
}

.extensionsView {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.46);
    pointer-events: none;

    opacity: 0;
    z-index: 10;

    transition: opacity .2s ease;
}

.cardContainer {
    position: relative;
    cursor: pointer;

    opacity: 1;
    transition: opacity 0.2s ease;
    &.canNotBeEaten {
        opacity: 0.2;
    }

    .extensions {
        position: absolute;
        bottom: 100%;
    }

    &:hover, &.hovered {
        .extensionsView {
            opacity: 1;
        }
    }

    .card {
        
    }

    .controls {
        position: absolute;
        bottom: 0;
        width: 100%;

        display: flex;
        justify-content: space-between;

        .left, .right {
            font-size: 1.5em;
            padding: 5px;
            cursor: pointer;
        }
    }
}

:global {.dragging-extension {:local {
    .extensionsView {
        opacity: 1;
    }
}}}