.centered {
  justify-content: center;

  :global .navbar-menu {
    flex-grow: 0;
  }
}

.logoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logo {
  margin-right: 10px;
}

.evolutionSign {
  text-transform: uppercase;
  font-weight: 500;
  color: white;
}

.gameState {
  color: black;
  padding: 10px;
}
