@import 'variables.scss';

.icon {
    font-size: 30px;
}

.cardContent {
    height: 100%;
    display: flex;
    flex-direction: column;

    .image {
        flex: 0 0 auto;
        padding: 5px;

        display: flex;
        align-items: center;
        justify-content: center;

        img {
            max-width: calc(100% - 10px);
            max-height: $cardHeight / 3;
        }
    }

    .description {
        flex: 1 1 auto;

        font-size: 10px;
        color: darken($color: $themeColor, $amount: 20);

        text-align: center;

        margin: 8px;
        overflow: hidden;
    }

    .use, .wasPlacedByUser {
        position: absolute;
        bottom: 5px;
        left: 5px;
        width: calc(100% - 10px);
    }

    .wasPlacedByUser {
        font-size: 0.65em;
        color: grey;
    }
}

.trigger {
    position: absolute;
    height: $cardHeight;
    width: $cardWidth;
    z-index: 20;
}

.container {
    position: relative;
    height: $cardHeight;
    width: $cardWidth;
    transform-style: preserve-3d;
    .back, .front {
        backface-visibility: hidden;
        position: absolute;
        height: $cardHeight;
        width: $cardWidth;

        top: 0;
    }
    .front {
        z-index: 2;
    }
}