@import "variables.scss";

.hand {
  flex-shrink: 0;
  display: contents;
  position: relative;

  pointer-events: none;

  .card {
    position: absolute;
    margin-left: -110px;
    transform: rotate(10deg);
    z-index: 1;

    .description {
      font-size: 10px;
      color: darken($color: $themeColor, $amount: 20);
      margin: 10px;
      text-align: center;
    }
  }

  &.left .bg,
  &.left .fg {
    transform: rotateY(180deg);
  }

  &.demo {
    .bg {
        z-index: 0;
    }
  }

  .bg,
  .fg {
    height: 300px;
    width: 200px;
    z-index: -1;
    background-size: cover;

    margin: 40px -55px 0;
  }

  .fg {
    margin-left: -145px;
    z-index: 10;
  }
}
