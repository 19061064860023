@import 'variables.scss';

$scale: 1.5;

.modal {
    position: fixed;

    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 200;

    background-color: #000000a7;
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 40px;

    .cardContainer {
        height: $cardHeight * $scale;
        width: $cardWidth * $scale;
        overflow: hidden;

        .card {
            transform: scale($scale);
            transform-origin: 0 0;
        }
    }

    .extensionsContainer {
        display: grid;
        
        gap: 10px;
    }
}