@import 'variables.scss';

.mainContainer {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
}

.background {
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: white;
  filter: brightness(0.5);
}

.container {
  display: flex;
  justify-content: center;
}

.openRoomItem {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;

  small {
    opacity: 0.6;
  }
}