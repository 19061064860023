.screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    background-color: #f3f5f5;
}