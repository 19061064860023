@import "variables.scss";

.noEvents {
  @include noEvents;
}

.tag {
  margin-right: 0.5rem;
}

.isRenderedAsFullScreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
}

.container {
  overflow: visible;
  background: #8080801c;
  height: calc(100% - 42px);
  width: 100%;
  position: absolute;

  &.active {
    box-shadow: inset 0px 0px 20px 2px $themeColor;
  }

  .meta {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 10;
  }

  .image {
    height: 70px;
    position: fixed;
    bottom: 5px;
    left: 5px;
    pointer-events: none;
    overflow: hidden;
    z-index: 5;
  }

  .controls {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 10;

    &.horizontal {
      flex-direction: row;
    }

    .button {
      margin: 5px;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #000000;
      border-radius: 5px;
      background: #f9f9f9;
      color: rgb(49, 49, 49);
      font-size: 1.5rem;
      cursor: pointer;
    }
  }
}

.animals {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
  padding: 10px;
  justify-content: center;
  align-items: center;

  height: 100%;

  &.vertical {
    flex-direction: column;
  }
}

.dropZoneContainer {
  flex-shrink: 0;
  width: $cardWidth;
  display: flex;
  justify-content: center;
}

.dropZone {
  height: $cardHeight;
  width: 0;
  transition: width 0.2s ease, color 0.2s ease;

  background: #8080801c;
  overflow: hidden;
  font-size: 60px;
  color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  color: #8080807c;
}
