@import "variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700&display=swap");

* {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  min-height: 100vh;
  user-select: none;
  background-color: #f3f5f5;
  --toastify-color-success: $themeColor;

  input,
  textarea,
  [contenteditable] {
      -webkit-user-select: text;
      user-select: text;
  }
}

#root {
  background-color: #f3f5f5;
}

/** Customize Bulma */

$primary: $themeColor;
$container-max-width: 600px;
$navbar-height: $navbarHeight;

@import "../node_modules/bulma/bulma.sass";
