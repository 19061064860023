$themeColor: #016b43;

$cardHeight: 200px;
$cardWidth: 145px;

$navbarHeight: 3.25rem;

@mixin noEvents {
    pointer-events: none;
    border: 2px solid black !important;

    &>* {
        filter: blur(3px);
    }
}