@import 'variables.scss';

.board {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: calc(100vh - $navbarHeight);

    .foodContainer {
        width: 70px;
        height: 100%;
        flex: 0 0 70px;
    }

    .rest {
        flex: 1 1;
        height: 100%;
        overflow: hidden;
    }
}