.line {
    position: fixed;
    left: 0;
    top: 0;
    will-change: transform, width, height;
    background: black;
    width: 2px;
    height: 2px;
}

.label {
    position: fixed;
    left: 0;
    top: 0;
    will-change: transform;
    width: 120px;
    background: rgba(201,201, 201, 0.7);
    text-align: center;
    padding: 5px;
    border-radius: 4px;
    margin-top: -28px;
    font-size: 12px;
    margin-left: -60px;
}