@import 'variables.scss';

.arrow {
    position: fixed;
    left: 0;
    top: 0;
    transform-origin: left center;

    display: flex;
    flex-direction: row;
    align-items: center;

    .body {
        flex-grow: 1;
        background: #6b0101;
        height: 8px;
    }

    .head {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 12px 0 12px 12px;
        border-color: transparent transparent transparent #6b0101;
    }
}