@import 'variables.scss';

.deck {
    width: 100%;
    height: 100%;
    background: rgb(1 107 67 / 12%);

    display: flex;
    justify-content: center;

    transition: bottom .4s ease;

    &.noEvents{
        @include noEvents;
    }

    .cards {
        width: calc(100% - $cardWidth);

        display: flex;
        align-items: flex-start;
        justify-content: center;

        .card {
            flex-shrink: 50;
            flex-basis: 100px;
            height: 200px;
            transform-origin: 50% 50%;

            position: relative;

            display: flex;
            justify-content: center;

            .cardContent {
                position: absolute;

                transition: .2s ease transform;
                &:hover {
                    transform: translate(0, -30px);
                }
            }
        }
    }
}