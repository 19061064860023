.container_settings {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.button_start {
  margin-top: 7px;
}

.plus_minus {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.bot {
  height: 35px;
  width: 35px;
  margin: 5px;
}
.margin {
  margin: 5px;
}

.table {
  text-align: center;
}

.image {
  height: 20vh;
  width: 20vw;
}
