.tag {
    font-size: 10px !important;
    margin-bottom: 0.2rem !important;
    margin-right: 0.2rem !important;

    &.blue {
        color: #01356b;
    }
    &.red {
        color: #6b0101;
    }
    &.pink {
        color: #c25392;
    }
    &.yellow {
        color: #8d7a00;
    }
    &.disabled {
        background: linear-gradient(115deg, rgb(0 0 0) 0%, rgb(255, 255, 255) 40%);
    }
    &.used {
        opacity: 0.8;
    }
}