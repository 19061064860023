@import 'variables.scss';

.foodBase {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    flex-direction: column;

    opacity: 0.5;

    &.active {
        opacity: 1;
    }
}

.foodItem {
    padding: 3px;
    border-radius: 4px;
    display: inline-block;
    color: white;
    margin: 1px;
    font-size: 30px;
    filter: drop-shadow(0px 0px 5px black);
}

.extension {
    margin: 1px;
    z-index: 10;
    filter: drop-shadow(0px 0px 5px black);
}