.background {
    pointer-events: none;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: white;
    filter: brightness(0.5);
  }

.row {
    display: flex;
    flex-direction: row;
    pointer-events: none;
    position: relative;
}

.rows {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}