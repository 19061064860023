@import 'variables.scss';

.button {
    position: fixed !important;
    bottom: 30px;
    right: 30px;
    height: 50px;
    
    z-index: 1000;

    opacity: 1;
    &.disabled {
        opacity: 0.5;
    }

    transition: transform .2s ease, opacity .2s ease;
    transform-style: preserve-3d;

    &.flip {
        transform: rotateX(180deg);
    }

    .ready,
    .notReady {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        opacity: 0;
        transition: opacity .2s ease;
    }

    .ready {
        opacity: 1;
        pointer-events: all;
    }

    .notReady {
        transform: rotateX(180deg);
        z-index: -1;
        pointer-events: none;
    }

    &.flip {
        .notReady {
            opacity: 1;
            pointer-events: all;
        }
        .ready {
            opacity: 0;
            pointer-events: none;
        }
    }
}