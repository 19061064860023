@use "sass:math";
@import 'variables.scss';

$hoverTransformPercent: 0.5;

.container,
.card {
    height: $cardHeight;
    width: $cardWidth;
}

.card {
    --theme-color:#{ $themeColor};
    border: var(--theme-color) 3px solid;
    border-radius: 10px;

    &.onlyHeader {
        height: auto;
        border-bottom: 1px var(--theme-color)  solid;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        margin-bottom: -6px;transform-origin: 50% $cardHeight;
    }

    background-color: white;
    box-shadow: 0 0 7px 2px rgb(0 0 0 / 11%);

    display: flex;
    flex-direction: column;

    will-change: transform;
    transition: transform .2s ease;
    &.blue {
        --theme-color: #01356b;
    }
    &.red {
        --theme-color: #6b0101;
    }
    &.pink {
        --theme-color: #c25392;
    }
    &.yellow {
        --theme-color: #8d7a00;
    }
    &.disabled {
        background: linear-gradient(115deg, rgb(0 0 0) 0%, rgb(255, 255, 255) 40%);
        .titleContainer {
            background-color: transparent;
        }
    }
    &.used {
        opacity: 0.8;
    }

    &.hoverable:hover {
        transform: scale(1 + $hoverTransformPercent);
        z-index: 100;
        position: absolute;
    }

    .titleContainer {
        min-height: 6px;
        background-color: white;
        border-bottom: 1px var(--theme-color)  solid;
        display: flex;
        align-items: center;
        font-size: 11px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    .title {
        flex-grow: 1;

        padding: 5px;

        color: var(--theme-color) ;
        font-weight: 300;
        text-align: center;
        text-transform: uppercase;

        word-break: break-word;
    }

    .afterTitle {
        padding: 5px;
        color: red;
    }
}

@keyframes unHover {
    0% {
        transform: scale(1 + $hoverTransformPercent);
    }

    100% {
        transform: scale(1);
    }
}

:global {
    .dragging {
        :local {
            .card {
                transform: scale(1 + $hoverTransformPercent / 3);
            }
        }
    }
}


:global {
    .hovered {
        :local {
            .card {
                border-color: rgb(124, 0, 152);
            }
        }
    }
}